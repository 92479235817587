import React from 'react'
import Layout from '../../components/layout'
import { WordSearchCreator } from '../../components/ChallengeCreators'

const WordSearchCreatorPage = ({ challengeId }) => (
  <Layout>
    <WordSearchCreator challengeId={challengeId} />
  </Layout>
)

export default WordSearchCreatorPage
